import React from 'react';
import styled from 'styled-components';
import BenifitCard from './BenifitCard/BenifitCard';

const StyledDiv = styled.div`
  width: 100%;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  .mainHeading {
    display:flex;
    align-items:center;
    justify-content:center;
    padding-top:30px;
   padding-bottom:30px;
   font-size:30px;
    font-weight:600;
    font-family:'Harmonia Sans','Nunito Sans',sans-serif;
    @media(max-width:767px){
      text-align:center;
    }
  }
  .banifitCards {
    width: 100%;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;
const benifitsList = [
  {
    id: 1,
    title: 'Healthy',
    subTitle: 'Aging',
    imgSrc:
      'https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ProLon-Atom-Compressed.gif?v=1600326408',
    detailText:
      'Triggers the natural process of cleaning and rejuvenation of your cells, which supports healthy aging.*',
  },
  {
    id: 1,
    title: 'Kickstart',
    subTitle: 'Healthy Habits',
    imgSrc:
      'https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ProLon-DNA-Compressed.gif?v=1600327237',
    detailText:
      'Change your relationship with food, reduce your food cravings and gain better portion control.',
  },
  {
    id: 1,
    title: 'Enhance',
    subTitle: 'Performance',
    imgSrc:
      'https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ProLon-Accelerate-Compressed.gif?v=1600327435',
    detailText: 'Improve your mental clarity, focus, and energy.',
  },
  {
    id: 1,
    title: 'Weight Loss',
    subTitle: 'Results',
    imgSrc:
      'https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ProLon-OnTarget-Compressed.gif?v=1600327658',
    detailText:
      'Kickstart weight loss (on average 5 pounds), especially belly fat, while protecting lean body mass.',
  },
];

const BenifitSection = () => {
  const products = benifitsList.map((benifit) => {
    return (
      <BenifitCard
        key={benifit.id}
        imgSrc={benifit.imgSrc}
        title={benifit.title}
        subTitle={benifit.subTitle}
        detailText={benifit.detailText}
      />
    );
  });
  return (
    <StyledDiv className="page-width">
      <div className="mainHeading">
        <h2>
          Unlock the Transformative Benefits of the Fasting Mimicking Diet®
        </h2>
      </div>
      <div className="banifitCards">{products}</div>
    </StyledDiv>
  );
};

export default BenifitSection;
